import { useState } from "react";
import { ThemeContext } from "./Contexts/Context";
import Main from "./Main";
import Intro from "./Components/Intro";
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import { Proj1, Proj2 } from "./Data/WorkData";
import Header from "./Components/Header";
import Footer from "./Components/Footer";

const App = () => {
  const [theme, setTheme] = useState(true);
  const toggleTheme = () => {
    setTheme((curr) => (curr === true ? false : true));
  };


  return (
    <Router >
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      <div className="App" id={theme ? "light" : "dark"}>
        <Header />
        <Routes>
          <Route path="/"  element={<Main />} />
          <Route path="/ply"  element={<Intro {...Proj1}/>} />
          <Route path="/lawn_sprinklers"  element={<Intro {...Proj2}/>} />
        </Routes>
        <Footer />

      </div>
    </ThemeContext.Provider>
    </Router>
  );
};

export default App;

import { FaReact, FaJsSquare, FaNode} from "react-icons/fa"
import { TbBrandNextjs, TbBrandCss3} from "react-icons/tb"
import { ImHtmlFive} from "react-icons/im"
export default function Services(){

    return(
        <section className="my-services" id="services">
            <h2 className="section__title section__title--services">My Skills</h2>
            <div className="services">

            <div className="service">
                    <h3> <ImHtmlFive className="skill-logo" />
                    <br /> <span className="skill-head">HTML5</span></h3>
                    <p></p>
                </div> 

            <div className="service">
                    <h3> <TbBrandCss3 className="skill-logo" />
                    <br /> <span className="skill-head">CSS3</span></h3>
                    <p></p>
                </div> 
   
                <div className="service">
                    <h3> <FaJsSquare className="skill-logo" />
                        <br /> <span className="skill-head" >JavaScript</span>  </h3>
                    <p></p>
                </div> 

                <div className="service">
                    <h3> <TbBrandNextjs className="skill-logo" />
                    <br /> <span className="skill-head">NextJS</span></h3>
                    <p></p>
                </div> 

                <div className="service">
                    <h3> <FaReact className="skill-logo" />
                    <br /> <span className="skill-head">ReactJS</span></h3>
                    <p></p>
                </div> 
                
                <div className="service">
                    <h3> <FaNode className="skill-logo" />
                       <br /> <span className="skill-head">Node.js</span></h3>
                    <p></p>
                </div> 
            </div> 
            
            <a href="#work" className="btn">My Work</a>
        </section> 
    )
}
import { FaGithub, FaLinkedin, FaReact} from "react-icons/fa"

export default function Footer(){ 
    
    return(
        <footer className="footer" >
            
            <div className="footer-info">
            <a href="mailto:ashishsunny.dev@gmail.com" className="footer__link"><img src="../images/pixel_art_me.png" className="pixel-art" alt="pixel_art"/></a>
                <img src="../images/clickme.png" alt="" className="clickme"/>
            </div>
            
            {/* <ul className="social-list" >
                <li className="social-list__item"><a className="social-list__link" href="https://github.com/ashishsunny">
                <i className="fa-brands fa-github"><FaGithub /></i>
                 
                </a></li>
                <li className="social-list__item"><a className="social-list__link" href="https://www.linkedin.com/in/ashishsunny/">
                    <i className="fa-brands fa-linkedin-in"><FaLinkedin /></i>

                </a></li>
            </ul> */}
            <div className="footer-end">
               <span className="footer-end1">Built with</span> <span className="footer-end2"><FaReact className="footer-icon" /></span>
            </div>
        </footer>
    )
}
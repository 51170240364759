
export default function Intro({titleMain, titleSub, titleEmo, Cap, url, CapCap, alt, href }) {

  return (
    <section className="intro" id="home">
      <h1 className="section__title section__title--intro">
        <a className="intro-link" href={href}>{titleMain}</a>{titleEmo}
         <strong>{titleSub}</strong>
        
      </h1>
      <p className="section__subtitle section__subtitle--intro">{Cap}<span className="cap-bold">{CapCap}</span></p>
      <img
        src= {`../images/${url}`}
        alt={alt}
        className="intro__img"
      />
    </section>
  );
}

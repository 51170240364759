import React from "react";
import Work from "./Components/Work";
import Intro from "./Components/Intro";
import Services from "./Components/Services";
import AboutMe from "./Components/AboutMe";
import { Obj1 } from "./Data/Data";

const Main = () => {
  return (
    <div> 
      <Intro {...Obj1}/>
      <Services />
      <AboutMe />
      <Work />
    </div>
  );
};

export default Main;

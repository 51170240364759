const Obj1 = {
    titleMain :"Hello there!",
    titleEmo:"👋🏽",
    titleSub :"I'm Ashish",
    Cap:"",
    CapCap:"Front-End Dev",
    url:"ashishsunny01.jpg",
    alt:"ashish sunny",
    href:"https://c.tenor.com/WuOwfnsLcfYAAAAC/star-wars-obi-wan-kenobi.gif"
}

module.exports = {
    Obj1
}
export default function Projects({url}){
    return(
        <>
          <img
            src={`../images/${url}`}
            alt=""
            className="portfolio__img"
          />
        </>
    )
}
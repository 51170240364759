export default function Services() {
  return (
    <section class="about-me" id="about">
      <h2 className="section__title section__title--about">About Me</h2>
      <p className="section__subtitle section__subtitle--about">
        I am a design-focused React Developer
      </p>

      <div className="about-me__body">
        <p>
        I craft user friendly front-end systems that are maintainable and scalable.
        </p>
        <p>
          I work with JS, and I use React as my library of choice. I have also worked with other libraries like Bootstrap, Tailwind, ExpressJS & NodeJS... 
        </p>
      </div>

      <img
      
        src={
          "../images/ashishsunny02.jpeg"
        }
        alt="Me capturing a moment while camping"
        className="about-me__img"
      />
    </section>
  );
}

const Proj1 = {
    id: 1,
    titleMain : "live site",
    titleSub :"Plywood",
    Cap:"ReactJS | responsive",
    CapCap:"",
    url:"ply.png",
    alt:"plywood factories react landing page image",
    href:"https://plywoodfactory.netlify.app/"
}
const Proj2 = {
    id: 2,
    titleMain : "live site",
    titleSub :"II Business",
    Cap:"ReactJS | scalable",
    CapCap:"",
    url:"lawn_sprinklers.png",
    alt:"irrigation insallations react landing page image",
    href:"https://irrigationinstallation.netlify.app/"
}

module.exports = {
    
    Proj1,
    Proj2
    
}

// Build using React & Styled Components | Mobile & Desktop Responsive styling | Scalable FE Architecture
// Built in ReactJS | Mobile & Desktop Responsive styling| Scalable FE Architecture
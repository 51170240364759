import Projects from "./Projects"
import { Link } from "react-router-dom";
import { Proj1, Proj2, Proj3 } from "../Data/WorkData";


export default function Work() {
  return (
    <section className="my-work" id="work">
      <h2 className="section__title section__title--work">My Work</h2>
      <p className="section__subtitle section__subtitle--work">
        A selection from my range of work
      </p>

      <ul className="portfolio">

        <li className="portfolio__item"><Link to="/ply"><Projects  {...Proj1}/></Link></li>
        <li className="portfolio__item"><Link to="/lawn_sprinklers"><Projects  {...Proj2}/></Link></li>
        <li className="portfolio__item"><Link to="/projects"><Projects  {...Proj3}/></Link></li>

      </ul>
    </section>
  );
}




// export default function Work() {
//   return (
//     <section className="my-work" id="work">
//       <h2 className="section__title section__title--work">My work</h2>
//       <p className="section__subtitle section__subtitle--work">
//         A selection of my range of work
//       </p>

//       <div className="portfolio">
//         <a href="/" className="portfolio__item">
//           <img
//             src="../images/portfolio-01.jpg"
//             alt=""
//             className="portfolio__img"
//           />
//         </a>

//         <a href="/" className="portfolio__item">
//           <img
//             src="../images/portfolio-02.jpg"
//             alt=""
//             className="portfolio__img"
//           />
//         </a>

//         <a href="/" className="portfolio__item">
//           <img
//             src="../images/portfolio-03.jpg"
//             alt=""
//             className="portfolio__img"
//           />
//         </a>

//         <a href="/" className="portfolio__item">
//           <img
//             src="../images/portfolio-04.jpg"
//             alt=""
//             className="portfolio__img"
//           />
//         </a>

//         <a href="/" className="portfolio__item">
//           <img
//             src="../images/portfolio-05.jpg"
//             alt=""
//             className="portfolio__img"
//           />
//         </a>

//         <a href="/" className="portfolio__item">
//           <img
//             src="../images/portfolio-06.jpg"
//             alt=""
//             className="portfolio__img"
//           />
//         </a>

//         <a href="/" className="portfolio__item">
//           <img
//             src="../images/portfolio-07.jpg"
//             alt=""
//             className="portfolio__img"
//           />
//         </a>

//         <a href="/" className="portfolio__item">
//           <img
//             src="../images/portfolio-08.jpg"
//             alt=""
//             className="portfolio__img"
//           />
//         </a>

//         <a href="/" className="portfolio__item">
//           <img
//             src="../images/portfolio-09.jpg"
//             alt=""
//             className="portfolio__img"
//           />
//         </a>

//         <a href="/" className="portfolio__item">
//           <img
//             src="../images/portfolio-10.jpg"
//             alt=""
//             className="portfolio__img"
//           />
//         </a>
//       </div>
//     </section>
//   );
// }

